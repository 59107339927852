.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  max-width: 100%;
}

.title {
  color: white;
  font-family: var(--font-halisR);
  font-size: 2rem;
  text-transform: uppercase;
}

.subtitle {
  color: var(--color-mountain-meadow);
  font-family: var(--font-signika);
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.separator {
  width: 8px;
}