body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
  background-color: black;
}

/* To get color name use this site: https://chir.ag/projects/name-that-color/#FFFFFF */
/* Variables */
:root {
  /* COLORS */
  --color-main-white : #FFFFFF;
  --color-main-green : #17BF87;
  --color-main-purpule : #5B41F0;
  --color-main-black : #000000;
  --color-main-red : #FF0000;

  --color-mirage : #161E2A;
  --color-galerry : #EBEBEB;

  --color-red : #ED0C0C;
  --color-delete-red : #FF0E0E;
  --color-torch-red : #FF0E43;

  --color-broom : #FFEB20;

  --color-tree-poppy : #FF9820;
  
  --color-clay-ebony : #253144;
  --color-clay-ebony-medium : #222D3F;
  --color-clay-ebony-dark : #202A3A;

  --color-blue-bayoux: #505e75;
  --color-pickled-bluewood : #2A374D;
  --color-pickled-bluewood-dark : #263348;
  --color-blue-prussian : #00245A;
  --color-blue-oxford : #384354;
  --color-blue-dodger : #00BAFF;
  --color-blue-cornflower : #7057FF;
  --color-blue-cornflower-transparent : #7057ff91;

  --color-grey-santas : #9C99B1;
  --color-grey-athens : #F8F8F9;
  --color-grey-dusty : #979797;
  --color-grey-cod : #111111;
  --color-grey-silver-chalice : #ABABAB;

  --color-purpule-heart : #523BD1;

  --color-mountain-meadow : #17bf88;
  --color-salem : #0C835C;

  --color-rose-old : #C69A7E;
  --color-clam-shell : #DBC5B7;

  /* FONTS */
  --font-signika : Signika;
  --font-halisR : HalisR;

  /* SCROLL BAR */
  --scroll-bar-color-dark: var(--color-blue-oxford);
  --scroll-bar-color: var(--color-blue-bayoux); /* colors.getBlueCornflower() */
  --scroll-bar-background-color: rgba(255, 255, 255, 0);
}

/* --------------------------------------- 
--------------------------------------- 
              TOAST
--------------------------------------- 
--------------------------------------- 
*/
.toast {
  box-shadow: 0px 2px 10px #00000088 !important;
  --toastify-color-light: var(--color-clay-ebony-medium);
  --toastify-text-color-light: white;
  --toastify-font-family: var(--font-signika);
}

.Toastify__close-button--light {
  color: white !important;
}