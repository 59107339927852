.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-signika);
}

.timezoneContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  margin-left: 5px;
  color: grey;
  font-size: 0.9rem;
}