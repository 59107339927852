/* --parge-marge is from PageContainer component */

.container {
  border-radius: 10px;
  background-color: var(--color-pickled-bluewood-dark);
  padding: var(--page-marge);
  display: flex;
  flex-direction: column;
}

.table {
  --table-marge: var(--page-marge);
  min-height: 0;
}

.tableUndefinedValue {
  color: grey;
}

.emptyDataMessage {
  font-size: 1rem;
  font-family: var(--font-signika);
  text-align: center;
  color : white;
}

.storageSpacesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
}

.storageSpaceContainer {
  position: relative;
  flex: 1;
  border-radius: 10px;
  background-color: var(--color-pickled-bluewood-dark);
  padding: var(--page-marge);
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.storageSpaceName {
  width: 100%;
  font-size: 1.2rem;
  font-family: var(--font-signika);
}

.storageSpaceValues {
  font-size: 1rem;
  font-family: var(--font-signika);
}

.storageSpaceVideos {
  font-size: 0.8rem;
  font-family: var(--font-signika);
}


.storageSpaceProgressBar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.progressBar {
  flex: 1;
  height: 10px;
  --inner-color: var(--color-blue-cornflower);
}

.progressBarValue {
  width: 50px;
  text-align: center;
  color: white;
}

.storageSpaceLimitReached {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1rem;
  font-family: var(--font-signika);
}

.storageSpaceLimitReachedMessage {
  width: 100%;
  margin-top: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: var(--font-signika);
  text-align: center;
}

.buttonContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: var(--page-marge);
  gap: 1rem;
  flex-wrap: wrap;
}