/* --parge-marge is from PageContainer component */

.container {
  border-radius: 10px;
  background-color: var(--color-pickled-bluewood-dark);
  padding: var(--page-marge);
  min-height: 250px;
  display: flex;
  flex-direction: column;
}

.table {
  --table-marge: var(--page-marge);
  min-height: 0;
}

.tableUndefinedValue {
  color: grey;
}

.dateContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dateTitle {
  color: white;
  font-family: var(--font-signika);
  font-size: 1.2rem;
  margin: 5px 5px 5px 0px;
}

.dateValue {
  color: white;
  font-family: var(--font-signika);
  font-size: 1rem;
}

.buttonContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: var(--page-marge);
  gap: 1rem;
  flex-wrap: wrap;
}

.popupUpdateTimezoneContent {}

.popupUpdateTimezoneTitle {
  color: white;
  font-family: var(--font-signika);
  margin-bottom: 10px;
}

.popupUpdateTimezoneValue {
  margin-top: 20px;
  color: white;
  font-family: var(--font-signika);
}

.timezoneSelector {
  padding: 10px;
  border-radius: 5px;
  background-color: var(--color-pickled-bluewood);
}