:root {
  --menu-default-width: 350px;
  --menu-small-width: 50px;
  --menu-background-color: var(--color-clay-ebony);

  --menu-width: var(--menu-default-width);
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: var(--menu-width);
  max-width: 99vw;
  /*min-width: var(--menu-width);*/
  background-color: var(--menu-background-color);
  z-index: 100;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 50%);
}

.container.hide {
  transform: translateX(calc(-100% - 5px));
  transition: ease 0.3s transform;
}
.container.show {
  transform: translateX(0);
}

.container.overlay {
  position: absolute;
  left: 0px;
  top: 0px;
}

.displayModeDefault.container {
  --menu-width: var(--menu-default-width);
}

.displayModeSmall.container {
  --menu-width: var(--menu-small-width);
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1.8rem 1.25rem;
  box-sizing: border-box;
  width: 100%;
}

.displayModeSmall .header {
  justify-content: center;
  margin: 2.5rem 0px;
  width: 100%;
}

.logo {
  height: 3rem;
  flex: 1;
  object-fit: contain;
}

.displayModeSmall .logo {
  display: none;
}

.smallLogo {
  position: absolute;
  max-width: 75%;
  max-height: 100%;
  opacity: 0;
  display: none;
}

.displayModeSmall .smallLogo {
  display: block;
  opacity: 1;
  transition: opacity 1s ease 0.5s;
}

.displayModeSmall:hover .smallLogo {
  transition: unset;
  opacity: 0;
}
.displayModeSmall:hover .buttonDisplayMode {
  transition: unset;
  opacity: 1;
}

.iconDisplayMode {
  width: 1.25rem !important;
  transform: rotate(-90deg);
}


.buttonDisplayMode {
  width: 3rem;
  height: 3rem;
  opacity: 60%;
  box-sizing: border-box;
  border-radius: 10px;
}

.displayModeDefault .buttonDisplayMode {
  opacity: 1;
  transition: ease 0.3s transform;
}

.displayModeSmall .buttonDisplayMode {
  position: absolute;
  transform: rotate(180deg);
  opacity: 0;
  transition: transform 0.3s ease, opacity 1s ease 0.3s;
  width: 2.6rem;
  height: 2.6rem;
}


.displayModeSmall .buttonDisplayMode:hover {
  background-color: var(--color-clay-ebony-medium);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 1.25rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.displayModeSmall .content {
  width: 100%;
  padding: 0px;
}

.items {
  width: 100%;
}

.disconnectContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  gap: 0.35rem;
  text-decoration: none;
}

.disconnectLogo {
  width: 1.5rem;
  opacity: 60%;
}
.displayModeSmall .disconnectLogo {
  width: 20px;
}

.disconnectLabel {
  font-size: 1rem;
  font-family: var(--font-signika);
  color: grey;
}
.displayModeSmall .disconnectLabel {
  display: none;
}

