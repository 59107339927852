.targetName {
  position: relative;
}

.targetName::after {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #FFFFFFAA;
  position: absolute;
  left: 0;
  bottom: -3px;
}