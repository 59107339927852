
.blurredBackground {
  /* The image used */
background-image: url("../../assets/background.png");

/* Add the blur effect */
filter: blur(8px);
-webkit-filter: blur(8px);

/* Full height */
height: 100%;

/* Center and scale the image nicely */
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.2); /* Black w/opacity/see-through */
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  text-align: center;
}

.icon {
  height: 80px;
  width: 80px;
  padding: 30px;
}

.title {
  font-family: var(--font-signika);
  color: var(--color-blue-prussian);
  font-size: 20;
  font-weight: 600;
  padding: 0px 15px;
  margin-bottom: 15px;
  text-align: center;
}

.subTitle {
  font-family: var(--font-signika);
  color: var(--color-blue-prussian);
  font-size: 18px;
  font-weight: 300;
  padding: 0px 15px;
  text-align: center;
}

.contact {
  color: var(--color-blue-oxford);
}