
/* ------------------------ DEBUG ------------------ */
/*
* {
  border: 1px solid black;
  background-color: rgba(100, 100 ,100, 0.2);
} 
*/
/* ---- END DEBUG ---- */


html, body, #root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

html{
  font-size:16px;
}


@media screen and (max-width: 700px) {
  html{
    font-size:15px;
  }
}

@media screen and (max-width: 600px) {
  html{
    font-size:14px;
  }
}

@media screen and (max-width: 500px) {
  html{
    font-size:13px;
  }
}

@media screen and (max-width: 400px) {
  html{
    font-size:12px;
  }
}

@media screen and (max-width: 300px) {
  html{
    font-size:10px;
  }
}





/* --------------------------------------- 
--------------------------------------- 
            FONTS
--------------------------------------- 
--------------------------------------- 
*/


@font-face {
  font-family: 'Signika';
  src: local('Signika'), url(../assets/font/Signika.ttf) format('truetype');
}
@font-face {
  font-family: 'HalisR';
  src: local('HalisR'), url(../assets/font/HalisR.otf) format('opentype');
}

/* --------------------------------------- 
--------------------------------------- 
            SCROLL BAR
--------------------------------------- 
--------------------------------------- 
*/

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-background-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-bar-background-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  margin: 1px;
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-background-color);
}

/* --------------------------------------- 
--------------------------------------- 
            GLOBAL
--------------------------------------- 
--------------------------------------- 
*/

.text-wrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
