.devicesList {
  display: flex;
  flex-direction: column;
  height: 100%; /* So the selection box won't be cropped */
  overflow-y: hidden;
}

.listHeader {
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #222a38;
  color: #a7a8ab;
  gap: 0.6rem;
  padding: 0.6rem 0px 0.6rem 0.6rem;
}

.selectGroup {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.selectModePickerOpenButton {
  cursor: pointer;
  position: relative;
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 5px;
}

.selectModePickerOpenButton:hover {
  background-color: #344055;
}

.selectModePickerOpenButton::after {
  content: "";
  position: absolute;
  top: 0.3rem;
  right: 0.45rem;
  height: 0.3rem;
  width: 0.3rem;
  border: solid #92959b;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.selectModePicker {
  position: fixed;
  transform: translate(0px, calc(50% + 1rem));
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.1);
  z-index: 5;
  backdrop-filter: blur(5px) brightness(130%);
}

@supports not (backdrop-filter: blur(5px) brightness(130%)) {
  .selectModePicker {
    background-color: rgba(77, 88, 109, 0.5);
  }
}

.selectModeItem {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;
  color: white;
  padding: 5px 10px 5px 10px;
  white-space: nowrap;
}

.selectModeItem:hover {
  background-color: rgba(105, 121, 150, 0.2);
}

.listContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 5px;
  gap: 2px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.listContent::-webkit-scrollbar {
  /*display: none;*/
}

/* Hide scrollbar for IE, Edge and Firefox */
.listContent {
  /*-ms-overflow-style: none;*/  /* IE and Edge */
  /*scrollbar-width: none;*/  /* Firefox */
} 