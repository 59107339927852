.link_ip:-webkit-any-link:active {
  color: var(--color-blue-dodger);
}
.link_ip:-webkit-any-link {
  color: var(--color-blue-cornflower);
}

.nameContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.nameInput {
  flex: 1;
  min-width: 0;
  background-color: unset;
  border: unset;
  /*font-family: var(--font-halisR);*/
  font-family: unset;
  font-size: 1rem;
  color: white;
}

.nameInput::placeholder {
  font-size: 0.8rem;
  font-family: unset;
  color: var(--color-grey-dusty);
  opacity: 0.8;
}


.nameButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.nameValidateButton {
  padding: 3px;
  border-radius: 5px;
  background-color: var(--color-main-green);
  color: white;
  min-width: 20px;
  text-align: center;
}

.nameUnvalidateButton {
  padding: 3px;
  border-radius: 5px;
  background-color: var(--color-torch-red);
  color: white;
  min-width: 20px;
  text-align: center;
}

.onlineContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.onlineIndicator {
  cursor: default;
  font-size: 0.9rem;
}