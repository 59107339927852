.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: min(320px, 95%);
  max-width: min(700px, 80%);
  max-height: 80%;
  border-radius: 0.7rem;
  overflow: hidden;
  background-color: var(--color-pickled-bluewood);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 40%);
}

.header {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 1rem;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  background-color: var(--color-pickled-bluewood-dark);
}

.headerContent {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  font-size: var(--font-signika);
  color: white;
  font-family: var(--font-signika);
  font-size: 1.2rem;
}

.closeButton {
  padding: 10px;
  color: var(--color-blue-cornflower);
}


.contentContainer {
  box-sizing: border-box;
  margin: 1rem 1rem 0px 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  color: white;
  white-space: pre-wrap;
  border-radius: 10px;
  background-color: var(--color-pickled-bluewood-dark);
  overflow-y: auto;
  overflow-x: hidden;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  color: white;
  font-size: 1rem;
}

.contentText {
  flex: 1;
}

.notShowAgainContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  color: white;
  font-size: 1rem;
}

.buttonsContainer {
  height: 3.75rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.buttonContainer {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.1rem;
  color:white;
  white-space: pre-wrap;
  font-size: var(--font-signika);
}

.buttonType_cancel {
  color: var(--color-torch-red);
}

.buttonType_ok {
  color:white;
}

.buttonType_validate {
  color:var(--color-blue-cornflower);
}

.buttonDisabled {
  color: grey;
}