:root {
  --table-padding-left: 0px;
  --table-padding-right: 0px;
  --table-padding-top: 0px;
  --table-padding-bottom: 0px;
  --table-justify-content: flex-start; /* see flee: justify-content*/
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 100%;
  min-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
}

.table {
  min-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: var(--table-justify-content);
  align-items: center;
  height: 2.5rem;
  min-height: 2.5rem;
  width: 100%;
  box-sizing: border-box;
  padding: var(--table-padding-top) var(--table-padding-right) var(--table-padding-bottom) var(--table-padding-left);
}

.headerCell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 0.35rem;
  flex-wrap: wrap;
}

.headerCellContent {
  display: block;
  max-width: 100%;
  max-height: 100%;

  /* overflow */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.headerCell.sort {
  cursor: pointer;
}

.headerSortIcon {
  display: none;
  width: 0.6rem;
}

.headerSortIcon.ascendant {
  display : block;
  transform: rotate(180deg);
}

.headerSortIcon.descendant {
  display : block;
}

.headerSortIcon.no_sort {}

.content {
  padding: var(--table-padding-top) var(--table-padding-right) var(--table-padding-bottom) var(--table-padding-left);
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  direction: rtl; /* TO HAVE SCROLL BAR AT LEFT #1 */
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: var(--table-justify-content);
  align-items: center;
  width: inherit;
  box-sizing: border-box;
  direction: ltr; /* TO HAVE SCROLL BAR AT LEFT #2 */
}

.rowCell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  min-width: 0;

  color: white;
  
  /* DEV /
  background-color: grey;
  border: 1px solid black;
  /* - */
}


.rowCellContent {
  display: block;
  max-width: 100%;
  max-height: 100%;

  /* overflow */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}