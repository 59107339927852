/* --parge-marge is from PageContainer component */

.container {
  border-radius: 10px;
  background-color: var(--color-pickled-bluewood-dark);
  padding: var(--page-marge);
  min-height: 250px;
  display: flex;
  flex-direction: column;
}

.table {
  --table-marge: var(--page-marge);
  min-height: 0;
}

.tableUndefinedValue {
  color: grey;
}