.container {
  border-radius: 0.6rem;
  padding: 0.6rem 1.2rem;
  font-size: 1em;
  font-family: var(--font-halisR);
  width: fit-content;
  text-align: center;
}

.disabled {
  opacity: 80%;
}