.container {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  position: relative;
  display: inline-block;
  width: 1.5rem;
  height: 1.125rem;
  line-height: 1em;
}