.container {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

.content {
  overflow: hidden;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid rgb(193, 26, 26);
  background-color: rgb(37, 49, 68);
  font-size: 1em;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
  padding: 10px;
}

.title {
  font-size: 1.5em;
  background-color: rgb(193, 26, 26);
  padding: 5px;
  border-radius: 5px;
}

.closeIcon {
  height: 25px;
}

.animation {
  animation: shaking-animation 0.5s;
}

@keyframes shaking-animation {
  0% { transform:  translateX(0) }
  25% { transform: translateX(10px) }
  50% { transform: translateX(-10px) }
  75% { transform: translateX(10px) }
  100% { transform: translateX(0) }
}