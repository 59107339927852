.container {
  position: relative;
  min-width: 80px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
  background: var(--color-pickled-bluewood);
  border-radius: 10px;
}

.value {
  font-family: var(--font-signika);
  color: var(--color-blue-cornflower);
  width: 1000%;
  box-sizing: border-box;
  text-align: center;
}