
.container {
  width: 100%;
  text-align: center;
}

.support {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.text{
  text-align: left;
}

.progressDownloadContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 300px;
}

.progressBardDownloadContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.progressBar {
  flex: 1;
  height: 10px;
  --inner-color: var(--color-blue-cornflower);
}

.progressBarValue {
  width: 50px;
  text-align: center;
  color: white;
}

.buttonContainer {
  max-width: 100%;
  width: 160px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}