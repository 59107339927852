/* --parge-marge is from PageContainer component */

.container {
  border-radius: 10px;
  background-color: var(--color-pickled-bluewood-dark);
  padding: var(--page-marge);
  min-height: 250px;
  display: flex;
  flex-direction: column;
}

.table {
  --table-marge: var(--page-marge);
  min-height: 0;
}

.tableUndefinedValue {
  color: grey;
}

.newVersionsContainer {
  margin-top: var(--page-marge);
}

.newVersionsHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.newVersionsHeaderMessage {
  color : white;
}

.newVersionContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px var(--page-marge);
  border-radius: 10px;
  background-color: var(--color-pickled-bluewood);
  margin-top: 10px;
  font-family: var(--font-halisR);
  color: white;
}

.newVersionContainer .title {
  font-size: 1rem;
}

.newVersionContainer .value {
  font-size: 1rem;
  margin-left: 5px;
}

.newVersionContainer .value.version {
  color: var(--color-mountain-meadow);
}

.buttonContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: var(--page-marge);
  gap: 1rem;
  flex-wrap: wrap;
}

.emptyDataMessage {
  font-size: 1rem;
  font-family: var(--font-signika);
  text-align: center;
  color : white;
}

.popupContentScheduleContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.popupContentSchedulePickerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.popupContentScheduleWarningContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex;
  align-items: center;
  gap: 10px;
  font-family: var(--font-signika);
  color: var(--color-broom);
}