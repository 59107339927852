.processingMessage {
  flex: 1;
  text-align: left;
}

.errorMessageContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.errorMessage {
  justify-content: flex-start;
}
