:root {
  --border-radius : 5px;
  --inner-color: grey;
  --background-color: white;
}
.container {
  border-radius: var(--border-radius);
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
  background-color: transparent;
  height: 10px;
}

.inner {
  width: 0%;
  min-width: var(--border-radius);
  height: 100%;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  background-color: var(--inner-color);
}

.background {
  flex: 1;
  background-color: var(--background-color);
  margin-left: calc(-1 * var(--border-radius));
}

.percentage {
  max-height: 100%;
  width: 100%;
  text-align: center;
  min-width: 50px;
  text-align: center;
  color: grey;
  font-size: 8px;
}