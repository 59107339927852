.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.displayModeSmall.container {
  gap: 0px;
}

.item {
  width: 100%;
}

.displayModeDefault .deviceList {
  max-height: max(250px, 30vh);
  --scroll-bar-color: var(--scroll-bar-color-dark);
}