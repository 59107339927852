.column, .container, .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.authContainer {
    text-align: center;
    background-color: rgb(37, 49, 68);
    border-radius: 5px;
    padding: 15px;
    gap: 5px;
}
.form {
    gap: 10px;
}
.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.button {
    border: none;
    cursor: pointer;
    border-radius: 3px;
    height: 30px;
    background-color: rgb(23, 191, 135);
    transition: all 0.3s ease;
    margin-top: 10px;
}
.button:hover {
    background-color: rgb(19, 148, 105);
}
.input {
    border: rgb(227, 226, 226) 1px solid;
    height: 30px;
    border-radius: 3px;
}
.input:disabled {
    border: 1px solid;
}
.loginTitle, .button {
    color: white;
}
.fieldError {
    text-align: left;
    color: red;
    font-size: 10px;
}
.loginTitle {
    line-height: 0px;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 40px;
}
.infoBox {
    border: solid 0.5px #9C99B1B8;
    background-color: #17BF87B8;
    border-radius: 10px;
    padding: 10px;
}
.infoBoxError {
    border: solid 0.5px #9C99B1B8;
    background-color: #FF000082;
    border-radius: 10px;
    padding: 10px;
}
.forgotPasswordText {
    color: blue;
    text-decoration-line: underline;
    font-family: 'Signika';
}