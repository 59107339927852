.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
  box-sizing: border-box;
  font-size: 1rem;
  color: white;
}
.timezoneRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.6rem;
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.timezoneRow.selected {
  background-color: var(--color-pickled-bluewood);
  color: var(--color-blue-cornflower);
}