
.container {
  width: 100%;
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statusBarContainer {
  width: 100%;
  height: 20px;
  background-color: var(--color-main-purpule);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
  box-shadow: 0px 0px 5px black;
}

.statusBarText {
  font-family: var(--font-signika);
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--color-main-white);
}

.statusBarLine {
  background-color: var(--color-main-black);
  height: 1px;
  opacity: 0.2;
  flex: 1;
  margin: 0px 20px;
}
