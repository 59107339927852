.mainContainer {
    width: 100%;
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.mobileBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    background-color: #222a38;
}

.mobileBarButton {
    height: 100%;
    padding: 0px 20px 0px 10px;
}

.main {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: row;
    min-height: 1px;
}

.rightPane {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
}

.socketBarOnline, .socketBarLoading, .socketBarOffline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    padding-top: 3px;
    padding-bottom: 3px;
    gap: 10px;
}

.socketBarOnline {
    background-color: green;
}

.socketBarLoading {
    background-color: orange;
}

.socketBarOffline {
    background-color: red;
}

.retryButton {
    margin: 0px;
    height: 20px;
}

.rightPaneContent {
    flex: 1;
    width: 100%;
    background-color: rgb(210, 210, 210);
    /*overflow: auto;*/
    min-height: 0px;
}


.deviceListWrapper {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    overflow: auto;
}
