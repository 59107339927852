/* --parge-marge is from PageContainer component */

.container {
  border-radius: 10px;
  background-color: var(--color-pickled-bluewood-dark);
  padding: var(--page-marge);
  min-height: 250px;
  display: flex;
  flex-direction: column;
}

.table {
  --table-marge: var(--page-marge);
  min-height: 0;
}

.tableUndefinedValue {
  color: grey;
}

.buttonContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: var(--page-marge);
  gap: 1rem;
  flex-wrap: wrap;
}