/* https://www.w3schools.com/howto/howto_css_blurred_background.asp */

.blurredBackground {
    /* The image used */
  background-image: url("../../assets/background.png");

  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.2); /* Black w/opacity/see-through */
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    text-align: center;
}

.form {
    width: min(80vw, 400px);
}