.container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  backdrop-filter: blur(1px) brightness(100%);
  
}

.container::before {
  position: absolute;
  content: '';
  background-color: var(--color-blue-bayoux);
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}